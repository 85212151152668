import Clipboard from '@stimulus-components/clipboard'
import { hasTurboPermanentParent, controllerPreviouslyConnected, setControllerConnectedDataAttribute } from '../utils/connection_status'

export default class extends Clipboard {
  connect () {
    if (!hasTurboPermanentParent(this.element) || !controllerPreviouslyConnected(this.element)) {
      super.connect()
    }

    if (hasTurboPermanentParent(this.element)) {
      setControllerConnectedDataAttribute(this.element)
    }
  }
}
