import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['requiredCheckbox', 'hiddenRequiredCheckbox', 'exactCheckbox', 'hiddenExactCheckbox', 'metadataField', 'form']

  skip (event) {
    this.updateMetadataWithOnlyHiddenCheckboxData()
    this.submitForm(event)
  }

  submit (event) {
    this.updateMetadataWithAllCheckboxData()
    this.submitForm(event)
  }

  submitForm (event) {
    event.preventDefault()
    Turbo.navigator.submitForm(this.formTarget)
  }

  updateMetadataWithAllCheckboxData () {
    const selectedRequiredConditions = this.requiredCheckboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.name)
    const selectedExactConditions = this.exactCheckboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.name)

    this.updateMetadata(selectedRequiredConditions, selectedExactConditions)
  }

  updateMetadataWithOnlyHiddenCheckboxData () {
    const selectedRequiredConditions = this.hiddenRequiredCheckboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.name)
    const selectedExactConditions = this.hiddenExactCheckboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.name)

    this.updateMetadata(selectedRequiredConditions, selectedExactConditions)
  }

  updateMetadata (selectedRequiredTargets, selectedExactTargets) {
    const metadataValue = JSON.parse(this.metadataFieldTarget.value)
    this.updateConditions(metadataValue.query.targets, selectedRequiredTargets, selectedExactTargets)
    this.metadataFieldTarget.value = JSON.stringify(metadataValue)
  }

  updateConditions (targets, selectedRequiredTargets, selectedExactTargets) {
    targets.forEach(target => {
      target.conditions.forEach(condition => {
        const conditionId = condition.id.toString()
        condition.required = selectedRequiredTargets.includes(conditionId)
        condition.exact = selectedExactTargets.includes(conditionId)
      })

      const expansions = target.expansions || []
      expansions.forEach(expansion => {
        const expansionId = expansion.id.toString()
        const expansionConditions = this.childConditionsForExpansionId(target, expansionId)

        expansionConditions.forEach(expansionCondition => {
          expansionCondition.required = selectedRequiredTargets.includes(expansionId)
          expansionCondition.exact = selectedExactTargets.includes(expansionId)
        })
      })
    })
  }

  get configurableExactConditions () {
    return this.exactCheckboxTargets.map(checkbox => checkbox.name)
  }

  childConditionsForExpansionId (target, id) {
    return target.conditions.filter(condition => condition.parent_expansion_id != null && condition.parent_expansion_id.toString() === id)
  }
}
