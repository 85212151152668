import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    event: String,
    destination: String
  }

  fireEvent (event) {
    event.preventDefault()
    event.stopPropagation()

    window.parent.postMessage(`iframe:${this.eventValue}`, this.destination)
  }

  get destination () {
    return this.destinationValue || '*'
  }
}
