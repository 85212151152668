import { Controller } from '@hotwired/stimulus'
import { controllerPreviouslyConnected, setControllerConnectedDataAttribute } from '../utils/connection_status'

export default class extends Controller {
  static classes = ['expanded']
  static targets = ['previousSearches', 'userListicle']

  connect () {
    if (!controllerPreviouslyConnected(this.element)) {
      setTimeout(() => {
        this.setPreviousSearchesContainerHeight()
      }, 0)

      if (window.screen.width >= 1024) {
        this.element.classList.add('--no-transition')
        this.expandTray()
        setTimeout(() => {
          this.element.classList.remove('--no-transition')
        }, 200)
      }

      setControllerConnectedDataAttribute(this.element)
    }

    document.addEventListener('turbo:load', this.updateSelectedLink)
    this.updateSelectedLink()
  }

  expandTray () {
    this.element.classList.add(this.expandedClass)
    document.body.classList.add('overflow-hidden', 'md:overflow-auto')
  }

  collapseTray () {
    this.element.classList.remove(this.expandedClass)
    document.body.classList.remove('overflow-hidden', 'md:overflow-auto')
  }

  setPreviousSearchesContainerHeight () {
    if (!this.hasUserListicleTarget) return

    const maxHeight = this.userListicleTarget.offsetTop - this.previousSearchesTarget.offsetTop - 16
    this.previousSearchesTarget.style.maxHeight = `${maxHeight}px`
  }

  updateSelectedLink () {
    const windowPath = window.location.pathname
    const pathSegments = windowPath.split('/')
    let currentPath
    if (pathSegments.length > 2) {
      if (['bookmark_folders', 'applicant_screenings'].includes(pathSegments[1])) {
        currentPath = `/${pathSegments[1]}`
      } else {
        currentPath = `/${pathSegments[1]}/${pathSegments[2]}`
      }
    } else {
      currentPath = windowPath
    }

    const selectedLinks = document.querySelectorAll('.--selected')
    const linkElement = document.querySelector(`a[href="${currentPath}"]`)

    selectedLinks.forEach(function (selectedLink) {
      selectedLink.classList.remove('--selected')
    })

    if (linkElement) linkElement.classList.add('--selected')
  }
}
