import { Controller } from '@hotwired/stimulus'

import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    delay: Number,
    maxRequests: Number
  }

  connect () {
    this.requestCount = 0
    this.scheduleNextRequest()
  }

  async makeRequest () {
    if (this.requestCount < this.maxRequestsValue) {
      const response = await get(this.urlValue)

      if (response.status === 200) {
        this.requestCount++
        this.scheduleNextRequest()
      }
    }
  }

  scheduleNextRequest () {
    setTimeout(() => {
      this.makeRequest()
    }, this.delayValue)
  }
}
