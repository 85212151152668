import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'widgetContainer']
  static values = {
    siteKey: String
  }

  connect () {
    this.tokenReceived = false

    window.turnstile.ready(function () {
      // eslint-disable-next-line no-undef
      turnstile.render(this.widgetContainerTarget, {
        sitekey: this.siteKeyValue,
        appearance: 'interaction-only',
        callback: function (token) {
          this.tokenReceived = true
        }.bind(this)
      })
    }.bind(this))
  }

  submitForm (e) {
    e.preventDefault()
    e.target.disabled = true

    this.fireFormIfTokenRecieved()
  }

  fireFormIfTokenRecieved () {
    if (this.tokenReceived) {
      this.formTarget.dispatchEvent(new Event('submit', { bubbles: true }))
    } else {
      setTimeout(() => { this.fireFormIfTokenRecieved() }, 200)
    }
  }
}
