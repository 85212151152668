import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.textContent = 'Click me!'
  }

  updateTextContent () {
    this.element.textContent = "I've been clicked!"
  }
}
