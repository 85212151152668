import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'countdown']
  static values = {
    countdownSeconds: Number,
    rateLimitSeconds: Number,
    countdownText: String
  }

  connect () {
    this.timeout = null

    if (this.rateLimitSecondsValue > 0) {
      this.disableButtons()
      this.displayCountdown(this.rateLimitSecondsValue)
    }
  }

  disableButtons () {
    this.buttonTargets.forEach((button) => {
      button.classList.add('disabled')
    })
  }

  enableButtons () {
    this.buttonTargets.forEach((button) => {
      button.classList.remove('disabled')
    })
  }

  displayCountdown (secondsRemaining) {
    let countdownSeconds = secondsRemaining
    this.countdownTarget.classList.remove('hidden')

    this.timeout = setInterval(() => {
      countdownSeconds -= 1
      if (countdownSeconds <= 0) {
        clearInterval(this.timeout)
        this.enableButtons()
        this.countdownTarget.classList.add('hidden')
      } else {
        this.countdownTarget.textContent = `${this.countdownTextValue} ${countdownSeconds}s`
      }
    }, 1000)
  }

  disable (event) {
    this.disableButtons()
    this.displayCountdown(this.countdownSecondsValue)
  }
}
