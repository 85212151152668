import { Controller } from '@hotwired/stimulus'
import { ESCAPE } from '../utils/keycodes'

export default class extends Controller {
  static values = {
    turboFrame: String,
    dismissable: Boolean
  }

  connect () {
    this.showModal()

    if (this.dismissableValue) {
      this.keyupHandler = (event) => {
        if (event.keyCode === ESCAPE) {
          this.hideModal()
        }
      }
      document.addEventListener('keyup', this.keyupHandler)
    }

    this.onBeforeVisitTurboEvent = this.onBeforeVisitTurboEvent.bind(this)
    document.addEventListener(
      'turbo:before-visit',
      this.onBeforeVisitTurboEvent,
      false
    )
  }

  disconnect () {
    this.hideModal()
  }

  showModal () {
    document.body.style.overflow = 'hidden'
    document.querySelectorAll('.modal-backdrop').forEach((el) => {
      el.setAttribute('data-turbo-cache', false)
    })
    window.modal = this
  }

  get modalContainer () {
    return document.getElementById(this.turboFrameValue)
  }

  hideModal () {
    document.removeEventListener(
      'turbo:before-visit',
      this.onBeforeVisitTurboEvent,
      false
    )
    document.removeEventListener('keyup', this.keyupHandler)
    document.body.style.removeProperty('overflow')
    this.modalContainer.innerHTML = ''
    window.modal = null
  }

  onBeforeVisitTurboEvent (event) {
    this.hideModal()
  }
}
